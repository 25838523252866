import React, { Component } from 'react'
import Header from './layout/Header'
import Banner from './Common/Banner'
import { Link } from 'react-router-dom'

export default class sent extends Component {
    render() {
        return (
            <div>
                <Header />
                <Banner title="Message Sent Successfully" image="banners/Life.jpg" />
                <hr /><h6 style={{ textAlign: "center" }}><Link to="/">Go Back to Home</Link></h6><hr />
            </div>
        )
    }
}