import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class AboutPrincipal extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Principal Desk" image="banners/Principal.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="ttm-featured-wrapper">
                                    <div className="featured-thumbnail text-center">
                                        <img src={process.env.PUBLIC_URL + `/assets/images/aboutprincipal/principal.jpeg`} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 justify" style={{ margin: "auto" }}>
                                <div className="ttm-team-member-content shadow-box res-991-mt-30">
                                    <div className="ttm-team-member-content shadow-box res-991-mt-30">
                                        <div className="ttm-team-member-single-list">
                                            <a href="https://www.shoonyeka.com/bit-principal-production/" target="_blank"><h2 className="ttm-team-member-single-title">Dr. Rachana Shetty</h2></a>
                                            <span className="ttm-team-member-single-position" style={{fontFamily:"Cursive", fontWeight:"500"}}>MPT Cardiorespiratory (Ph.D)</span>
                                            <hr />
                                            <p>Dear Students,</p>
                                            <p>It gives me immense pleasure in welcoming you to Kengal Hanumanthaiah Institute of Physiotherapy which is an integral part of Rajya Vokkaligara Sangha.Our college is affiliated to esteemed RGUHS and offers Bachelor of physiotherapy (BPT) course.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 paddingtop-2 justify'>
                                <p>We provide quality education and training to the students for capacity building in the field of physiotherapy. We take pride in our infrastructural facilities for clinical teaching, well experienced </p>
                                <p>teaching faculty, student and staff support systems and excellent learning environment, for all round development of our students.Our college is attached to reputed KIMS hospital and Kengal Hanumanthaiah Hospital , giving our students access to excellent clinical experiences that will help them advance their practical knowledge. In order to showcase their talents, the students are also encouraged to take part in a variety of co- curricular and extracurricular activities.</p>
                                <p>In the fast evolving health care delivery systems, as well as the expansion and improvement of information and communication technologies,our endeavour is to focus on higher levels of learning, comprehension, analysis and application of knowledge and creating a competent level physiotherapists who can stand globally. Our emphasis is on developing the right attitude and approach towards patient.</p>
                                <p>With Best Regards</p>
                                <p><b style={{ fontWeight: "bold" }}>Dr. Rachana Shetty</b><br />Professor and Principal</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
