import React, { Component } from 'react';
import { Header } from './layout/Header';
import { CarouselPage } from './banner/Home1_banner';
import 'react-image-lightbox/style.css';
import Slider from 'react-slick';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../Config/FbConfig';
import Notification from './Common/Notification';

export class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  //Load Data Here
  async componentDidMount() {
    const docRef = doc(firestore, "Home", "Notification");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = []
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(Object.assign({ Id: key }, value))
      });
      this.setState({
        Datas: Data,
        Loading: false
      })
    } else {
      this.setState({
        Datas: "NO_999",
        Loading: false
      })
    }
  }

  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,

      responsive: [{

        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {

        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {

        breakpoint: 680,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    };

    return (
      <div className="site-main">
        <Header />
        <CarouselPage />
        <Notification />

        <section className="ttm-row history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h2 className="title"><span>WHY CHOOSE KHIPT?</span></h2>
                    <h5>“Where students from all over the world gather to learn how to reach beyond their potential.”</h5>
                  </div>
                </div>
              </div>
            </div>
            <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider}>

              <div className="ttm-box-col-wrapper">
                <div className="ttm-history-box-wrapper">
                  <div className="ttm-history-box-icon-wrapper">
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className="flaticon flaticon-business-and-finance" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  <div className="ttm-history-box-details ttm-bgcolor-white equal">
                    <i style={{ fontSize: "3rem", marginBottom: "1rem" }} className="fab fa-font-awesome" />
                    <div className="ttm-historybox-description">Kengal Hanumanthaiah Institute of Physiotherapy is located in a serene environment in Bangalore city which is ideal for educational institution.</div>
                  </div>
                </div>
              </div>

              <div className="ttm-box-col-wrapper">
                <div className="ttm-history-box-wrapper">
                  <div className="ttm-history-box-icon-wrapper">
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className="flaticon flaticon-business-and-finance" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  <div className="ttm-history-box-details ttm-bgcolor-white equal">
                    <i style={{ fontSize: "3rem", marginBottom: "1rem" }} className="fa fa-building" />
                    <div className="ttm-historybox-description">It is a unit of well reputed and service oriented KIMS hospital of Rajya Vokkaligara Sangha.</div>
                  </div>
                </div>
              </div>

              <div className="ttm-box-col-wrapper">
                <div className="ttm-history-box-wrapper">
                  <div className="ttm-history-box-icon-wrapper">
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className="flaticon flaticon-business-and-finance" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  <div className="ttm-history-box-details ttm-bgcolor-white equal">
                    <i style={{ fontSize: "3rem", marginBottom: "1rem" }} className="fas fa-hospital" />
                    <div className="ttm-historybox-description">Our students will be provided with hands on approach at a well equipped 1400 bedded KIMS Hospital to attain confidence in patient handling in order to produce competent physiotherapists</div>
                  </div>
                </div>
              </div>

              <div className="ttm-box-col-wrapper">
                <div className="ttm-history-box-wrapper">
                  <div className="ttm-history-box-icon-wrapper">
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className="flaticon flaticon-business-and-finance" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  <div className="ttm-history-box-details ttm-bgcolor-white equal">
                    <i style={{ fontSize: "3rem", marginBottom: "1rem" }} className="fa fa-book" />
                    <div className="ttm-historybox-description"> Students are trained under well experienced physiotherapy teaching faculties who are also recognised Post Graduate guides by Rajiv Gandhi University of Health Sciences (RGUHS).</div>
                  </div>
                </div>
              </div>

              <div className="ttm-box-col-wrapper">
                <div className="ttm-history-box-wrapper">
                  <div className="ttm-history-box-icon-wrapper">
                    <div className="featured-icon-box">
                      <div className="featured-icon">
                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                          <i className="flaticon flaticon-business-and-finance" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-history-box-border" />
                  <div className="ttm-history-box-details ttm-bgcolor-white equal">
                    <i style={{ fontSize: "3rem", marginBottom: "1rem" }} className="fa fa-medkit" />
                    <div className="ttm-historybox-description">Coaching is provided at our Kempegowda Institute of Medical Sciences by our highly skilled medical college faculty members with well established infrastructure.</div>
                  </div>
                </div>
              </div>

            </Slider>
          </div>
        </section>

      </div>
    )
  }
}

export default Home;