import React, { Component } from 'react';
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import Loading from "../../components/Common/Loading";
import ModalImage from "react-modal-image";
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';

export class Blog extends Component {

    state = { Datas: [] }

    async componentDidMount() {
        //For Teaching
        const docRef = doc(firestore, "Other", "Blogs");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign(
                    {
                        Id: key,
                        Header: value.Header,
                        Description: value.Description,
                        Priority: value.Priority != undefined ? value.Priority : "999",
                        Image: value.Image,
                    }))
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

        }

        else { this.setState({ Datas: "NO_999" }) }
    }

    constructor(props) {
        super();
    }

    render() {
        return (
            <div className="site-main">
                <Header />
                <Banner title="Blog" image="banners/Blog.jpg" />

                <div className="sidebar ttm-bgcolor-white clearfix paddingtop-2">
                    <div className="container-xl">
                        {
                            this.state.Datas.length === 0 ? <Loading /> :
                                this.state.Datas === "NO_999" ? <h2 style={{ textAlign: "center" }}><hr />No Blogs Found<hr /></h2> :
                                    this.state.Datas.map((data, i) => (
                                        <div className="col-lg-12" key={i}>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="gallery-box">
                                                        <ModalImage className="swaraimage1" small={data.Image} large={data.Image} alt="Image" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-8" style={{ margin: "auto" }}>
                                                    <p style={{ color: "black", fontWeight: "bold" }}>{i+1 + ". " + data.Header}</p>
                                                    <p className="multine-ellipsis" style={{ color: "black", textAlign:"justify"}}>{data.Description}</p>
                                                    <Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to={"/blog-single/" + data.Id}>Read More</Link>
                                                </div>
                                            </div>
                                            <br/><hr/><br/>
                                        </div>
                                    ))
                        }
                    </div>
                </div>
            </div>
        )
    }
}


export default Blog;