import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class LifeScope extends Component {
    render() {
        return (
            <div>
                <Header />
                <Banner title="Scope of Learning" image="banners/Life.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekhipt/College-11.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-9 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">Our learning methodology's motto is "maximise knowledge," and it emphasises "build competences" and "inculcate professional ethics and values." So, in order to facilitate integration of clinical and basic sciences, we adopt a problem-based learning method and cutting-edge educational strategies. The courses are meant to offer both knowledge and skills related to physiotherapy.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">The following are the key components of our learning methodologies :</h5>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Develop students into independent learners who will apply their knowledge and skills in their jobs and vocations.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Provide exemplary learning experiences that serve as learning resources.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Allow the learner to create their own meaning.</li>
                                        </div>
                                        <div className="col-md-6">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Motivate students to apply knowledge immediately to improve job performance.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Encouraging self-directed and self-regulated learning strategies.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Guiding students in the perspective of social and professional roles.</li>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        )
    }
}
