import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class LifeFundamental extends Component {
  render() {
    return (
      <div>
        <Header />
        <Banner title="Standards of Conduct" image="banners/Life.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl paddingtop-2">
            <div className="ttm-service-single-content-area">
              <div className="row">
                <div className="col-md-3">
                  <div className="ttm_single_image-wrapper">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekhipt/College-1.jpg"} alt="single-img-eighteen" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="ttm_single_image-wrapper">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekhipt/College-2.jpg"} alt="single-img-eighteen" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="ttm_single_image-wrapper">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekhipt/College-3.jpg"} alt="single-img-eighteen" />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="ttm_single_image-wrapper">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekhipt/College-4.jpg"} alt="single-img-eighteen" />
                  </div>
                </div>
                <div className="col-md-12 paddingtop-2" style={{ margin: "auto" }}>
                  <p className="about-para">The efficacy and performance of students in various extracurricular activities has been demonstrated to increase through the use of Experiential Learning, sports, and other Learning Principles.</p>
                  <p className="about-para">Here, we are listing a set of activities that are convoluted during sports ,cultural events at KHIPT in order to augment countless personality skills. The students of KHIPT are always keen to voluntarily participate in various medical camps,world health day events and medical quiz organized by various national level physiotherapy colleges and oranizations.Speaking about abilities, the most important skills in a student displays in comportment and conversation. That is why KHIPT trains the students in soft skills, communication skills, games, Leadership skills and many more extra Learning curiculum by encouraging their students to participate in various inter and intra college competition like quiz,debate,sports ,cultural activities,etc</p>
                </div>
              </div>
              <div className="ttm-service-description">
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h5 className="title">Academical excellence and high-quality learning</h5>
                  </div>
                </div>
                <p className="about-para">The KengaI Hanumanthaiah Institute of Physiotherapy is committed to offering top-notch education and developing a setting where academic achievements can grow to its full potential.</p>
              </div>

              <div className="ttm-service-description">
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h5 className="title">Diversity</h5>
                  </div>
                </div>
                <p className="about-para">The KHI of Physiotherapy provides the finest education to all the students aspiring to become a physiotherapist while being consistent with the varied cultural backgrounds of the country.</p>
              </div>

              <div className="ttm-service-description">
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h5 className="title">Morality and integrity</h5>
                  </div>
                </div>
                <p className="about-para">Without moral virtue and character integrity, no civilization can advance. Therefore, KHI of Physiotherapy gives it all to instil these two basic principles in its employees and students.</p>
              </div>

              <div className="ttm-service-description">
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h5 className="title">Community accountibility</h5>
                  </div>
                </div>
                <p className="about-para">Kengal Hanumanthaiah Institute of Physiotherapy is aware of its social responsibilities and makes an effort to do actions that will benefit society as a whole.</p>
              </div>

              <div className="ttm-service-description">
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h5 className="title">Performance and funvtional tranparency</h5>
                  </div>
                </div>
                <p className="about-para">The college adheres to a policy of performance and functional transparency in its operations in order to maximise the involvement and participation of its employees and students in the achievement of the institution's goals and mission.</p>
              </div>

              <div className="ttm-service-description">
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h5 className="title">Accountability towards the environment</h5>
                  </div>
                </div>
                <p className="about-para">The College's core premise still places a high priority on creating a cleaner and safer environment for everyone.</p>
              </div>

              <div className="ttm-service-description">
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h5 className="title">Gender equality</h5>
                  </div>
                </div>
                <p className="about-para">One of the egregious underlying issues that has repeatedly impeded national growth is gender bias. Kengal Hanumanthaiah Institute of Physiotherapy is therefore committed to making a meaningful contribution to the effort to eradicate gender bias in India.</p>
              </div>

              <div className="ttm-service-description">
                <div className="section-title with-desc text-left clearfix paddingtop-2">
                  <div className="title-header" style={{ borderTopColor: "red" }}>
                    <h5 className="title">Good health for all</h5>
                  </div>
                </div>
                <p className="about-para">If society is not healthy, education loses its importance. Being a physiotherapy college, we are aware of this and work hard to create a citizenry that is educated and healthy. We adhere to all safe practices that must be followed in times of medical emergency, such as the most recent COVID-19 outbreak.</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
