import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class LifeFacilities extends Component {

    render() {
        return (
            <div>
                <Header />
                <Banner title="Facilities" image="banners/Life.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekhipt/College-10.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                        <div className="ttm-service-description justify">
                                            <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To provide supervised clinical and skill training.</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Faculty with Industry and Academic Experience.</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Interactive Learning with experienced professionals of respective field.</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Best Library equipped with numerous books to help students in each subjects.</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Learning through practical orientation.</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Best exercise and electrotherapy lab.</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>As a unit of reputed KIMS hospital,best clinical teaching environment.</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>KHIPT OPD for clinical exposure.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">In keeping with its strong pro-green tradition, we at KHIPT feel that the quality of campus maintenance reflects the quality of education. KHI of Physiotherapy upholds the optimal infrastructure standards for its colleges.</p>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">Our physical classrooms and clinical laboratories offer smart classrooms with technological enhancements and infrastructure including interactive whiteboards and classroom response systems that can improve students' and staff's overall learning and explaining experience.. The institution has ergonomically designed seating furniture, Wi-Fi enabled LCD projectors in the classrooms, tutorial rooms, and a incampus 200 bedded Kengal Hanumanthaiah hospital and KIMS hospital attached for clinical exposure. Our college has a cutting-edge library with a significant amount of books, a wide selection of books for the various academic subjects, as well as National and International Journals and Magazines. The college has taken action to meet the need for surveillance and security.</p>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">KHIPT staff members are constantly coming up with inventive methods to use technology to help teaching and learning in both online and offline learning environments because KHIPT is committed to providing the best education possible to its students.A conducive atmosphere is created in the classrooms to help students study more effectively.Students therefore benefit from these facilities to enrich their educational experience and enable the blended learning model, which has become a norm.</p>
                                    </div>
                                    <div className="col-md-12 justify">
                                        <p>Hostel and mess facility is available. Fees will be separate for the same .For details kindly contact college office. We also have free transportation facility to provide our students with hassle-free reach to their classes conducted by our medical college faculty and for their clinical postings in our hospital.</p>
                                    </div>
                                </div>
                                <div className="row paddingtop-1">
                                    <div className="col-md-3">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekhipt/College-11.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekhipt/College-12.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekhipt/College-13.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekhipt/College-14.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
