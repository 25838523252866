import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export class Menu extends Component {
    render() {
        return (
            <nav id="menu" role="navigation" className="menu">
                <ul>
                    <li><NavLink to="/">Home</NavLink></li>
                    <li className="menu-hasdropdown"><a>About</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/About-Management'>About Management</NavLink></li>
                            <li><NavLink to='/About-College'>About College</NavLink></li>
                            <li><NavLink to='/About-Chairman'>Chairman Message</NavLink></li>
                            <li><NavLink to='/About-Principal'>Principal’s Desk</NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown"><a>Programme</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/Programme'>Bachelor of Physiotherapy</NavLink></li>
                        </ul>
                    </li>
                    <li><NavLink to="/Admission-Process">Admission</NavLink></li>
                    <li className="menu-hasdropdown"><a>Faculty</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/Faculty-Teaching'>Teaching Staff</NavLink></li>
                            <li><NavLink to='/Faculty-Administrative'>Administrative Staff</NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown"><a>Life @ KHIPT</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/Life-Fundamental'>Standards of Conduct</NavLink></li>
                            <li><NavLink to='/Life-Scope'>Scope of Learning</NavLink></li>
                            <li><NavLink to='/Life-Events'>Events and Activities</NavLink></li>
                            <li><NavLink to='/Life-Facilities'>Facilities</NavLink></li>
                        </ul>
                    </li>
                    <li><NavLink to="/Blog">Blog</NavLink></li>
                    <li><NavLink to="/Contact">Contact</NavLink></li>
                </ul>
            </nav>

        )
    }
}