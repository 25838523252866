import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import Loading from "../../components/Common/Loading";
import ModalImage from "react-modal-image";
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';

export default class LifeEvents extends Component {

    state = { Datas: [] }

    async componentDidMount() {
        const docRef = doc(firestore, "Home", "Events");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign(
                    {
                        Id: key,
                        Priority: value.Priority != undefined ? value.Priority : "999",
                        Image: value.Image,
                        Header: value.Description,
                    }))
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

        }

        else { this.setState({ Datas: "NO_999" }) }
    }

    render() {
        return (
            <div>
                <Header />
                <Banner title="Events & Activities" image="banners/Life.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <p className="about-para">The efficacy and performance of students in various extracurricular activities has been demonstrated to increase through the use of Experiential Learning, sports, and other Learning Principles.</p>
                                <p className="about-para">Here, we are listing a set of activities that are convoluted during sports ,cultural events at KHIPT in order to augment countless personality skills. The students of KHIPT are always keen to voluntarily participate in various medical camps,world health day events and medical quiz organized by various national level physiotherapy colleges and oranizations.Speaking about abilities, the most important skills in a student displays in comportment and conversation. That is why KHIPT trains the students in soft skills, communication skills, games, Leadership skills and many more extra Learning curiculum by encouraging their students to participate in various inter and intra college competition like quiz,debate,sports ,cultural activities,etc</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Dynamic Data */}
                <div className="container">
                    <div className="section-title with-desc text-left clearfix paddingtop-2">
                        <div className="title-header" style={{ borderTopColor: "red" }}>
                            <h4 className="title">Our Events</h4>
                        </div>
                    </div>

                    <div className="row">
                        {
                            this.state.Datas.length === 0 ? <Loading /> :
                                this.state.Datas === "NO_999" ? <div className="col-lg-12"><hr /><h6 style={{ textAlign: "center" }}>No Data Found</h6><hr /></div> :
                                    this.state.Datas.map((data, i) => (
                                        <div className="col-lg-4 paddingtop-2" key={i}>
                                            <div className="gallery-box">
                                                <ModalImage className="swaraimage1" small={data.Image} large={data.Image} alt="" />
                                            </div>
                                            <br />
                                            <p style={{ color: "black", textAlign: "center" }}>{data.Header}</p>
                                        </div>
                                    ))
                        }
                    </div>
                </div>
            </div>
        )
    }
}
