import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBD-cScL9yRYJRrwqqklGaJ6LAsePlWtvQ",
  authDomain: "rvs-khipt.firebaseapp.com",
  projectId: "rvs-khipt",
  storageBucket: "rvs-khipt.appspot.com",
  messagingSenderId: "22356743801",
  appId: "1:22356743801:web:aa40e934c19dcb13cee6a8",
  measurementId: "G-YZNC31V4MY"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export {auth, firestore , storage};