import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class Programme extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Programme" image="banners/Programme.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/programme/programme-1.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">The importance of physiotherapy courses has increased globally in light of evolving health care services and increased recognition of their significance.</p>
                                        <p className="about-para">For science majors who want to work in the medical field, physiotherapy is regarded as a lucrative career option. If a physiotherapist is well-versed in their field and skilled, they can make a good living doing it. One will learn a lot about the human body, injuries, and surgeries related to the human condition in this course.</p>
                                        <p className="about-para">Being a separate health profession, physiotherapy is able to both diagnose and treat movement disorders.It has emerged as a discipline of health science to</p>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">prevent, cure and rehabilitate physically disabled and challenged people.</p>
                                        <p className="about-para">It is a non-surgical intervention that aims to enhance the patient's quality of life by reducing pain, enhancing mobility, and regaining functionality.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h4 className="title">Bachelor of Physiotherapy</h4>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">A Bachelor's degree in physical therapy, or BPT, is a four and a half year undergraduate course of study in the scientific discipline. This undergraduate curriculum has been carefully designed to prepare students for careers in the fields of prevention, treatment, and rehabilitation for those who are physically, emotionally, and mentally challenged. The course consists of both classroom teaching and hands-on training in therapy clinics. BPT course prepares students to practise independently or under supervision as skilled, professional physical therapists. The course develops students' ability to reason in order to comprehend how to treat patients through thorough rehabilitation programmes, as well as their education and instruction on how to live a normal life.</p>
                                <a>Download Course Structure PDF</a>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h4 className="title">Career Scope</h4>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">After completing their undergraduate studies, students have the option of specialising in physical therapy or choose to work. Hospitals, private clinics, healthcare facilities, rehabilitation facilities, and NGOs all depend on physical therapists in one way or another. In order to improve and advance their professional expertise, they might choose from any working environments or they can further go for higher studies.</p>
                            </div>
                            <div className="ttm-service-description justify paddintop-2">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Clinical jobs in hospitals and clinics or can set up your own PT clinic.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Go for higher studies and be specialist in orthopaedics, Neurology, Cardio-respiratory, sports, Obs and Gynaecology, Community Based Rehabilitation.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Can opt for other fields too by doing Masters in Public health(M.P.H.),Msc.physiology Anatomy,e.t.c.</li>
                                        </div>
                                        <div className="col-md-6">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Overseas opportunities in UK, US, Canada, Australia, Middle East and European countries</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Be a consultant PT at IT sector (Industry)</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Work in a Geriatric care setup.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Can become a team lead in rehabilitation centre,</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Sports team physio.</li>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
