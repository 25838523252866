import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import NoData from "../../components/Common/NoData";
import Loading from "../../components/Common/Loading";
import ModalImage from "react-modal-image";
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';

export default class SpecificFaculty extends Component {

    state = { Datas: [] }

    async componentDidMount() {
        //For Teaching
        const docRef = doc(firestore, "FacultiesandStaff", "Teaching");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                if (key == this.props.match.params.id) {
                    Data.push(Object.assign(
                        {
                            Id: key,
                            Priority: value.Priority != undefined ? value.Priority : "999",
                            Image: value.Image,
                            Name: value.Name,
                            Description: value.Description,
                            Designation: value.Designation,
                            Qualification: value.Qualification
                        }))
                }
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

        }

        else { this.setState({ Datas: "NO_999" }) }
    }

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Teaching Staff" image="banners/Faculty.jpg" />

                {/* Dynamic Data */}
                <div className="container paddingtop-2">
                    {
                        this.state.Datas.length === 0 ? <Loading /> :
                            this.state.Datas === "NO_999" ? <NoData /> :
                                this.state.Datas.map((data, i) => (
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="gallery-box">
                                                <ModalImage className="swaraimage1" small={data.Image} large={data.Image} alt="Image" />
                                            </div>
                                        </div>
                                        <div className="col-lg-9" style={{ margin: "auto" }}>
                                            <p style={{ color: "black", fontWeight: "bold" }}>{data.Name}</p>
                                            <p style={{ color: "black" }}>
                                                {data.Qualification}<br />
                                                {data.Designation}<br /><br />
                                                {data.Description}
                                            </p>
                                        </div>
                                    </div>
                                ))
                    }
                    <br />
                </div>

            </div>
        )
    }
}
