import React, { Component } from "react";
import { MDBAnimation, MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer, MDBRow } from "mdbreact";
import { Link } from "react-router-dom";

export class CarouselPage extends Component {
    render() {
        return (
            <div>
                <MDBContainer fluid className="hide-on-mob">
                    <MDBRow>
                        <MDBCarousel activeItem={1} length={3} showControls={true} showIndicators={false} id="Home_banner">
                            <MDBCarouselInner>
                            <MDBCarouselItem itemId="1">
                                <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/Lander01.jpg"} alt="First slide" /></MDBView>
                                <MDBCarouselCaption>
                                    <div className="container-xl">
                                        <div className="carousel-caption-inner banner1">
                                            <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                                <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Kengal Hanumanthaiah Institute of</h3><strong className="ttm-textcolor-skincolor">Physiotherapy</strong></h2>
                                            </MDBAnimation>
                                            <MDBAnimation className="slideInUp" count={1} delay="0.5s" >
                                            <p className="text">Kengal Hanumanthaiah Institute of Physiotherapy is approved by Government of Karnataka and Affiliated to Rajiv Gandhi University of Health Sciences.</p>
                                            </MDBAnimation>
                                            <MDBAnimation className="slideInUp" count={1} delay="1s" >
                                            <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Admission-Form">Admission</Link></p>                            
                                            </MDBAnimation>
                                        </div>
                                    </div>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId="2">
                                <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/Lander02.jpg"} alt="Second slide" /></MDBView>
                                <MDBCarouselCaption>
                                    <div className="container-xl">
                                        <div className="carousel-caption-inner banner2">
                                            <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                                <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Bachelor's degree in </h3><strong className="ttm-textcolor-skincolor">Physiotherapy</strong></h2>
                                            </MDBAnimation>
                                            <MDBAnimation className="slideInUp" count={1} delay="0.5s" >
                                                <p className="text">A Bachelor's degree in Physiotherapy, or BPT, is a four and a half year undergraduate course of study in the scientific discipline.This undergraduate curriculum has been carefully designed to prepare students for careers in the fields of prevention, treatment, and rehabilitation.</p>
                                            </MDBAnimation>
                                            <MDBAnimation className="slideInUp" count={1} delay="1s" >
                                                <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Programme">Programme</Link></p>   
                                            </MDBAnimation>
                                        </div>
                                    </div>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>
                            <MDBCarouselItem itemId="3">
                                <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/Lander03.jpg"} alt="Third slide" /></MDBView>
                                <MDBCarouselCaption>
                                    <div className="container-xl">
                                        <div className="carousel-caption-inner banner1">
                                            <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                                <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Our Unique </h3><strong className="ttm-textcolor-skincolor">Scope of Learning</strong></h2>
                                            </MDBAnimation>
                                            <MDBAnimation className="slideInUp" count={1} delay="9.5s" >
                                            <p className="text">Our learning methodology's motto is "maximise knowledge," and it emphasises "build competences" and "inculcate professional ethics and values."</p>
                                            </MDBAnimation>
                                            <MDBAnimation className="slideInUp" count={1} delay="1s" >
                                                <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Life-Scope">Know More</Link></p>                  
                                            </MDBAnimation>
                                        </div>
                                    </div>
                                </MDBCarouselCaption>
                            </MDBCarouselItem>
                            </MDBCarouselInner>
                        </MDBCarousel>
                    </MDBRow>
                </MDBContainer>
                <MDBContainer fluid className="show-on-mob">
                    <MDBRow>
                    <MDBCarousel activeItem={1} length={3} showControls={true} showIndicators={false} id="Home_banner">
                        <MDBCarouselInner>
                        <MDBCarouselItem itemId="1">
                            <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/slider01.jpg"} alt="First slide" /></MDBView>
                            <MDBCarouselCaption>
                                <div className="container-xl">
                                    <div className="carousel-caption-inner banner1">
                                        <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                            <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Kengal Hanumanthaiah Institute of</h3><strong className="ttm-textcolor-skincolor">Physiotherapy</strong></h2>
                                        </MDBAnimation>
                                        <MDBAnimation className="slideInUp" count={1} delay="0.5s" >
                                        <p className="text">Kengal Hanumanthaiah Institute of Physiotherapy is approved by Government of Karnataka and Affiliated to Rajiv Gandhi University of Health Sciences.</p>
                                        </MDBAnimation>
                                        <MDBAnimation className="slideInUp" count={1} delay="1s" >
                                        <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Admission-Form">Admission</Link></p>                            
                                        </MDBAnimation>
                                    </div>
                                </div>
                            </MDBCarouselCaption>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId="2">
                            <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/slider02.jpg"} alt="Second slide" /></MDBView>
                            <MDBCarouselCaption>
                                <div className="container-xl">
                                    <div className="carousel-caption-inner banner2">
                                        <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                            <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Bachelor's degree in </h3><strong className="ttm-textcolor-skincolor">Physiotherapy</strong></h2>
                                        </MDBAnimation>
                                        <MDBAnimation className="slideInUp" count={1} delay="0.5s" >
                                            <p className="text">A Bachelor's degree in Physiotherapy, or BPT, is a four and a half year undergraduate course of study in the scientific discipline.This undergraduate curriculum has been carefully designed to prepare students for careers in the fields of prevention, treatment, and rehabilitation.</p>
                                        </MDBAnimation>
                                        <MDBAnimation className="slideInUp" count={1} delay="1s" >
                                            <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Programme">Programme</Link></p>   
                                        </MDBAnimation>
                                    </div>
                                </div>
                            </MDBCarouselCaption>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId="3">
                            <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/slider03.jpg"} alt="Third slide" /></MDBView>
                            <MDBCarouselCaption>
                                <div className="container-xl">
                                    <div className="carousel-caption-inner banner1">
                                        <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                            <h2 className="ttm-textcolor-darkgrey flipInY"><h3>Our Unique </h3><strong className="ttm-textcolor-skincolor">Scope of Learning</strong></h2>
                                        </MDBAnimation>
                                        <MDBAnimation className="slideInUp" count={1} delay="9.5s" >
                                        <p className="text">Our learning methodology's motto is "maximise knowledge," and it emphasises "build competences" and "inculcate professional ethics and values."</p>
                                        </MDBAnimation>
                                        <MDBAnimation className="slideInUp" count={1} delay="1s" >
                                            <p><Link className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor ttm-textcolor-white" to="/Life-Scope">Know More</Link></p>                  
                                        </MDBAnimation>
                                    </div>
                                </div>
                            </MDBCarouselCaption>
                        </MDBCarouselItem>
                        </MDBCarouselInner>
                    </MDBCarousel>
                    </MDBRow>
                </MDBContainer>
            </div>
        );
    }
}

export default CarouselPage;