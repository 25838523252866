import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import NoData from "../../components/Common/NoData";
import Loading from "../../components/Common/Loading";
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';

export default class AdmissionProcess extends Component {

    state = { Datas: [] }

    async componentDidMount() {
        //For Teaching
        const docRef = doc(firestore, "Home", "FeeStructure");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign(
                    {
                        Id: key,
                        Priority: value.Priority != undefined ? value.Priority : "999",
                        Heads: value.Heads,
                        When: value.When,
                        Mode: value.Mode,
                        Amount: value.Amount,
                    }))
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

        }

        else { this.setState({ Datas: "NO_999" }) }
    }

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Admission Process" image="banners/Admission.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">Bachelor of Physiotherapy is a full time 4 1/2 years degree course recognized by the Government of Karnataka and our college is affiliated to Rajiv Gandhi University of Health Sciences, Bangalore. The course meets the University’s stipulated requirements recongised by IAP.</p>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h4 className="title">B.P.T. Course Admission Fee Structure</h4>
                                </div>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: "20%" }}>Fees Heads</th>
                                        <th scope="col" style={{ width: "30%" }}>When to Pay</th>
                                        <th scope="col" style={{ width: "20%" }}>Mode of Payment</th>
                                        <th scope="col" style={{ width: "30%" }}>Amount (INR)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.Datas.length === 0 ? <Loading /> :
                                            this.state.Datas === "NO_999" ? <NoData /> :
                                                this.state.Datas.map((data, i) => (
                                                    <tr key={i}>
                                                        <td>{data.Heads}</td>
                                                        <td>{data.When}</td>
                                                        <td>{data.Mode}</td>
                                                        <td>{data.Amount}</td>
                                                    </tr>
                                                ))
                                    }
                                </tbody>
                            </table>
                            <div className="section-title with-desc text-left clearfix">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">Documents required at the time of Admission</h5>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>P.U.C pass Certificate / +12</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Mark list P.U.C. & 10th standard/SSLC</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Age proof Certificate</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Address proof & nationality certificate</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Transfer certificate</li>
                                        </div>
                                        <div className="col-md-6">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Migration certificate from the former University/Board</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Eligibility certificate from Rajiv Gandhi University of Health Sciences</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Conduct Certificate</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Medical fitness certificate from a Government Medical Officer</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Colour passport size photograph – 4</li>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <div className="section-title with-desc text-left clearfix">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">Other SAARC Internationals:</h5>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>10th Marks Card or equivalent certificate</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>10+2 Marks Card or equivalent certificate</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Transfer/leaving Certificate</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Citizenship certificate</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Migration Certificate</li>
                                        </div>
                                        <div className="col-md-6">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Character/Conduct certificate</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Birth Certificate</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Visa Copy</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>08 PP size and 04 stamp size Photographs</li>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h4 className="title">Eligibility Criteria For Admission</h4>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>A Candidate seeking admission to first year Bachelor in Physiotherapy (BPT) should have passed two year Pre University examination conducted by Department of Pre-University Education, Karnataka State, with English as one of the subjects and Physics, Chemistry and Biology as optional subjects. The candidate shall have passed subjects of English, Physics, Chemistry and Biology as optional subjects. The candidate shall have passed subjects of English, Physics, Chemistry and Biology individually also.OR</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Shall have passed any other examination conducted by Boards or Councils / Intermediate examination established by State Government / Central Government and recognized as equivalent to a two year Pre University Examination by Rajiv Gandhi University of Health Sciences / Association of Indian Universities (AIU), with English as one of the subjects and Physics, Chemistry and Biology as optional subjects. The candidate shall have passed subjects of English, Physics, Chemistry and Biology as optional subjects. The candidate shall have passed subjects of English, Physics, Chemistry and Biology individually also. OR</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Candidates who have completed Pre-university course with Vocational Physiotherapy as their optional subject are eligible for admission to BPT course. OR</li>
                                </ul>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">Foreign Nationals, Non Resident Indians and People of Indian Origin</h5>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">Should have studied in English with Physics, Chemistry and Biology as major subjects. Should have the eligibility certificate from the Rajiv Gandhi University of Health Sciences (RGUHS) to pursue physiotherapy education program in the state of Karnataka</p>
                            </div>
                            <div className="ttm-service-description justify">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i><b>Britain</b> : G.C.E. examination of U.K with pass in 5 subjects at least at Ordinary level and 2 subjects at Advanced Level, or 4 subjects at Ordinary level and 3 subjects at Advanced Level.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i><b>Kuwait</b> : General Secondary Education Certificate Examination (12 year course) conducted by the Ministry of Education, Kuwait.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i><b>United Arab Emirates</b> : General Secondary Education Certificate awarded by the Ministry of Education, United Arab Emirates.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i><b>United States of America</b> : 12-year High School Diploma of U.S.A.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i><b>Canada</b> : 12-year High School Diploma</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i><b>Iran</b> : Secondary School Certificate Examination (Community School Tehran). Sixth Year Certificate of Secondary Schools (Ministry of Education & Training) and 4th year Diploma of the Secondary Schools. (12 year course offered under the new pattern of School education).</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i><b>Malaysia</b> : Malaysian Certificate of Education (University of Cambridge Local Exam). Higher School Certificate Exam (University of Cambridge Local Exams syndicate in collaboration with University of Malaysia and University of Singapore). Sijil Pelajaran Malaysia / Sijil High School Graduation Diploma (International School of Kuala Lumpur)</li>
                                </ul>
                            </div>
                            <div className="section-title with-desc text-left clearfix">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">For Admission Enquiry Contact</h5>
                                </div>
                            </div>
                            <div className="contact_info_item">
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">Phone:</span>(080) 29550568</p>
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">Mobile:</span>+91 79754 24372</p>
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">Email:</span>kimskhipt@gmail.com</p>
                                <p className="f_400 f_size_15"><span className="f_400 t_color3">Address:</span>Kengal Hanumanthaiah College Of Physiotherapy, Near Nagarabhavi BDA Complex, Nagarabhavi 2nd Stage, Bengaluru-560091</p>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
