import React, { Component } from 'react';
import Slider from "react-slick";
import NoData from './NoData';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../Config/FbConfig';

export default class Notification extends Component {

    state = {
        Datas: [],
        Loading: true
    }

    //Load Data Here
    async componentDidMount() {
        const docRef = doc(firestore, "Home", "Notifications");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign({ Id: key }, value))
            });
            this.setState({
                Datas: Data,
                Loading: false
            })
        } else {
            this.setState({
                Datas: "NO_999",
                Loading: false
            })
        }
    }

    render() {

        var slick_slider = {
            dots: true,
            arrow: true,
            autoplay: true,
            autoplaySpeed: 2500,
            speed: 2000,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 680,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        return (
            <section className="ttm-row services-section ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes clearfix">
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="container-xl">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="section-title with-desc text-center clearfix">
                                <div className="title-header">
                                    <h2 className="title">Our <span className="ttm-textcolor-white">Notifications</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container xl paddingtop-2">
                        <Slider className="row no-gutters featured-icon-box-style1-row ttm-bgcolor-yellow" {...slick_slider}>
                            {this.state.Datas === undefined ? (<NoData />) : (
                                this.state.Datas.length &&
                                this.state.Datas.map(function (item, index) {
                                    return (
                                        <div key={index}>
                                            <div className="col-lg-12 col-md-4">
                                                <div className="featured-icon-box style5 text-left res-767-mb-20">
                                                    <div className="featured-content">
                                                        <div className="featured-desc"><p>{item.Header}</p></div>
                                                        <a className="ttm-btn ttm-btn-size-sm btn-inline mt-3" target="_blank" href={item.Content}>Know More <i className="ti ti-angle-double-right" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </Slider>
                    </div>
                </div>
            </section>
        )
    }
}
