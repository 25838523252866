import React, { Component } from "react";
import Header from "../layout/Header";
import Banner from "../Common/Banner";

export default class AdmissionForm extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        <Banner title="Admission Form" image="banners/Admission.jpg" />
        <section className="ttm-row zero-padding-section clearfix">
          <div className="container-xl">
            <div className="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span">
              <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                <div className="ttm-bg-layer-inner" />
              </div>
              <div className="section-title with-desc clearfix">
                <div className="title-header">
                  <h5>Register Now</h5>
                  <h2 className="title">Admission Open For 2024-25</h2>
                </div>
              </div>
              <form
                id="ttm-quote-form"
                className="row ttm-quote-form clearfix"
                method="post"
                action="adminform.php"
              >
                <div className="col-sm-6 col-md-6">
                  <div className="form-group">
                    <input
                      name="name"
                      type="text"
                      className="form-control bg-white"
                      placeholder="Full Name*"
                      required="required"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6">
                  <div className="form-group">
                    <input
                      name="phone"
                      type="text"
                      placeholder="Phone Number*"
                      required="required"
                      className="form-control bg-white"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6">
                  <div className="form-group">
                    <input
                      name="email"
                      type="text"
                      placeholder="Email Address*"
                      required="required"
                      className="form-control bg-white"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6">
                  <div className="form-group">
                    <input
                      name="Location"
                      type="text"
                      placeholder="Location*"
                      required="required"
                      className="form-control bg-white"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group">
                    <textarea
                      name="msg"
                      rows={5}
                      placeholder="Write A Message..."
                      required="required"
                      className="form-control bg-white"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="text-left">
                    <button
                      type="submit"
                      id="submit"
                      className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor"
                      value
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
