import React from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Layout
import { Footer } from './components/layout/Footer';

// pages
import Home from './components/Home';

import AboutManagement from "./components/about/AboutManagement";
import AboutCollege from "./components/about/AboutCollege";
import AboutChairman from "./components/about/AboutChairman";
import AboutPrincipal from "./components/about/AboutPrincipal";

import Programme from "./components/programme/Programme";

import AdmissionProcess from "./components/admission/AdmissionProcess";
import AdmissionForm from './components/admission/AdmissionForm';

import Teaching from "./components/faculty/Teaching";
import NonTeaching from "./components/faculty/NonTeaching";
import SpecificFaculty from "./components/faculty/SpecificFaculty";

import LifeFundamental from "./components/lifekhipt/LifeFundamental";
import LifeScope from "./components/lifekhipt/LifeScope";
import LifeEvents from "./components/lifekhipt/LifeEvents";
import LifeFacilities from "./components/lifekhipt/LifeFacilities";

import Blog from './components/blog/Blog';
import Single_blog from "./components/blog/Single_blog";

import Contact_01 from './components/contact/Contact_01';
import Gallery from "./components/gallery/Gallery";

import sent from './components/sent';
import ScrollToTop from './components/layout/Gotop';

const appHistory = {basename: process.env.PUBLIC_URL};
const history = createBrowserHistory(appHistory);
function App() {

  return (
    <div className="page">
      <Router basename={process.env.PUBLIC_URL} history={history}>
        <div id="preloader"><div id="status">&nbsp;</div></div>
        <ScrollToTop />
        <Route exact path='/' component={Home} />

        <Route path="/About-Management" component={AboutManagement} />
        <Route path="/About-College" component={AboutCollege} />
        <Route path="/About-Chairman" component={AboutChairman} />
        <Route path="/About-Principal" component={AboutPrincipal} />

        <Route path="/Programme" component={Programme} />

        <Route path="/Admission-Process" component={AdmissionProcess} />
        <Route path="/Admission-Form" component={AdmissionForm} />

        <Route path="/Faculty-Teaching" component={Teaching} />
        <Route path="/Faculty-Administrative" component={NonTeaching} />
        <Route path="/Faculty-Specific/:id" component={SpecificFaculty} />

        <Route path="/Life-Fundamental" component={LifeFundamental} />
        <Route path="/Life-Scope" component={LifeScope} />
        <Route path="/Life-Events" component={LifeEvents} />
        <Route path="/Life-Facilities" component={LifeFacilities} />

        <Route path="/blog" component={Blog} />
        <Route path="/blog-single/:id" component={Single_blog} />

        <Route path="/contact" component={Contact_01} />
        <Route path="/Gallery" component={Gallery} />

        <Route path="/Sent" component={sent} />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
