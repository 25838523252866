import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class AboutCollege extends Component {
    render() {
        return (
            <div>
                <Header />
                <Banner title="About College" image="banners/About.jpg" />
                
                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutcollege/college-1.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">The Kengal Hanumanthaiah Institute of Physiotherapy depicts how physiotherapy has developed into a crucial component of academic study since it is the most popular branch in biology due to the wide scope of the profession in India as well as in overseas. The Kengal Hanumanthaiah Institute of Physiotherapy was started by Rajya Vokkaligara Sangha at the Srigandhadakavalu campus connected to Kengal Hanumanthaiah Hospital and KIMS Hospital in Bangalore for the academic year 2020–21 in light of the evolving health care services and a growing awareness of the importance of physiotherapy. The statutory bodies gave their approval for the course to begin with an intake of 40 students. It is accredited by the Indian Association of Physiotherapists and associated with Rajiv Gandhi University of Health Sciences.</p>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">The college's objective is to advance knowledge in the field of physiotherapy and to educate its students so that they can assist the underprivileged and needy without hindrance. In order for students to aspire for excellence in all areas of academics and develop into highly qualified professionals in society, KHIPT instils moral principles among them in the best way possible.</p>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">The admission to the college is done on the basis of merit. Our highly experienced teaching  staff of Kempegowda Institute of Medical Sciences and Kengal Hanumanthaiah Institute of  Physiotherapy train the students theoretically and clinically.We have a well established KIMS  hospital and Out Patient Department(OPD) set up for clinical exposure which helps our  students to gain the knowledge, skills, attitudes and behaviours expected of an entry level  physiotherapist</p>
                                    </div>
                                </div>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h4 className="title">Vision</h4>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">Kengal Hanumanthaiah Institute of Physiotherapy established as a constituent of RVS is a growing institution which pledges to train globally competent physiotherapist who can deliver state of the art services for patient care.</p>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h4 className="title">Mission</h4>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To impart quality education in physiotherapy with evidence based practice and expansion of knowledge of the profession.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To provide supervised clinical and skill training.</li>
                                        </div>
                                        <div className="col-md-6">
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To produce individuals of high morality, integrity and emotional intelligence capable of working in a group.</li>
                                            <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>To create ambassadors of profession with high ethical values.</li>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}
